import { Canvas, ThreeEvent, useThree, Vector3 } from "@react-three/fiber";
import { Box, CameraControls, Center, Loader, OrbitControls, OrthographicCamera, useTexture  } from "@react-three/drei";

import { KeyPoint, KeypointGroup, Layer } from "../training/api/TrainingApi";
import { Text } from '@react-three/drei'
import { Exagard_Colors, GlobalAppsettings } from "../../GlobalSettings";
import { Suspense, useEffect, useRef, useState } from "react";

import * as THREE from "three";

import { useForceUpdate, useMediaQuery, useOs } from "@mantine/hooks";
import { useLanguage } from "../../common/language/Language";
import { BackgroundMap } from "./components/BackgroundMap";
import { KeyPointMarker } from "./components/KeypointMarker";
import { MergedWalls, WallSection, } from "./components/WallSection";
import { useMarkerLayer } from "./components/MarkerLayer";
import { Button, em, Menu, Stack } from "@mantine/core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Point, WallSectionDef } from "./api/Sections";
import { useMousePosition } from "./hooks/useMousePos";
import { RouteLayer } from "./api/RouteLayer";

import { Switch } from "@mantine/core";

function CanvasReady(props: {onReady: () => void}) {
    useEffect(() => {
      props.onReady();
    }, [])
    return null
  }




export const useMap = (props: {layer?: Layer, keyPoints?: KeyPoint[],
        mapLoaded?: () => void,
        saveKeyPoint: (keyPoint: KeyPoint, x: string, y: string, markerX: string, markerY: string) => void,
        saveRouteLayers: (routeLayers: RouteLayer[]) => void,
        editMode?: boolean,
        currentGroup?: KeypointGroup,
        routes?: RouteLayer[],
        isMobile: boolean
    }) => {

    const lang = useLanguage();

    const cameraControlsRef = useRef<CameraControls>(null);

    
    console.log("isMobile ->", props.isMobile);

    const [isDragging, setIsDragging] = useState(false);
    const floorPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

    const [cameraPosition, setCameraPosition] = useState<Vector3>([0, 40, 0]);
    const [currentZoom, setCurrentZoom] = useState(props.isMobile ? 25 : 40);

    const [currentKeyPoint, setCurrentKeyPoint] = useState<KeyPoint | undefined>(undefined);

    const [currentKeyPoints, setMapKeyPoints] = useState<KeyPoint[]>([]);

    const saveKeyPoint = (keyPoint: KeyPoint, position: THREE.Vector3, markerPosition: THREE.Vector3) => {
        if (props.saveKeyPoint) {
            props.saveKeyPoint(keyPoint, position.x.toString(), position.z.toString(), 
                markerPosition.x.toString(), markerPosition.z.toString());
        }
    }

    useEffect(() => {
        //setCameraPosition([0, props.isMobile ? 20 : 40, 0]);
        setCurrentZoom(props.isMobile ? 25 : 40);
    }, [props.isMobile]);

    const mobileOffset = 3;
    const desktopOffset = 3;

    useEffect(() => {
        if (props.keyPoints) {
            setMapKeyPoints(props.keyPoints);
        }
    }, [props.keyPoints]);

    useEffect(() => {
        if (props.routes) {
            console.log("routes ->", props.routes);
            setRouteLayers(props.routes);
        }
    }, [props.routes]);


    const forceUpdate = useForceUpdate();

    const [routeLayers, setRouteLayers] = useState<RouteLayer[]>([]);


    useEffect(() => {
        if (currentKeyPoint) {      
            if (cameraControlsRef.current) {
                
                {props.isMobile ? 
                (cameraControlsRef.current as any).setLookAt(
                    Number(currentKeyPoint.coordinateX), currentZoom, Number(currentKeyPoint.coordinateY) + mobileOffset,
                    Number(currentKeyPoint.coordinateX), 0, Number(currentKeyPoint.coordinateY) + mobileOffset,
                    true
                )
                :
                (cameraControlsRef.current as any).setLookAt(
                    Number(currentKeyPoint.coordinateX), currentZoom, Number(currentKeyPoint.coordinateY) + desktopOffset,
                    Number(currentKeyPoint.coordinateX), 0, Number(currentKeyPoint.coordinateY) + desktopOffset,
                    true
                )
                }
            }
            setMapKeyPoints(currentKeyPoints.map(kp => ({
                ...kp,
                isActive: kp.id === currentKeyPoint?.id,
                isDone: kp.isDone
            })));

            console.log("currentKeyPoint ->", currentKeyPoint);
           forceUpdate();
        }
    }, [currentKeyPoint])

    const renderMapLayer = (layer?: Layer) => {
        if (layer && layer.type === "map.layer.2d") 
            return <BackgroundMap floorPlane={floorPlane} url={layer.mediaRef} />
        //if (layer.type === "map.layer.3d")

        return null;
    }

    const renderLoading = () => {
        return (
        <Text color={Exagard_Colors._dark_green} anchorX="center" anchorY="middle"
            position={[0, 0.1, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1.1}
            fontWeight={1000}
                >
            {lang.Text("loading")}
        </Text>
        )
    }

    const showdirections = (keyPointRef: string, show: boolean) => {
        console.log("showDirectionsTo: ", keyPointRef, show);

        setActiveMarkerLayer(keyPointRef);
        //setCurrentZoom(50);
    }

    const [addPointMode, setAddPointMode] = useState(false);

    const renderTools = () => {    
    if (props.editMode) {
            return (
           <Stack>
            <Menu shadow="md" width={200}>
            <Menu.Target>
                <Button size="lg" variant="outline" color="lightgray" 
                    leftSection={<FontAwesomeIcon icon={solid("plus")} />} onClick={() => {
                
                }}>
            {lang.Text("")}
           </Button>
           </Menu.Target>
           <Menu.Dropdown>
                <Menu.Item disabled>
                    {lang.Text("Add Keypoint")}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={() => {
                    console.log("add route", currentKeyPoint);
                    let keypointId = currentKeyPoint?.id;
                    if (keypointId) {
                        const existingRouteLayer = routeLayers.find(rl => rl.keyPointRef === keypointId);
                        if (existingRouteLayer) {
                            console.log("Route already exists for this keypoint");
                        } else {
                            const newRouteLayer: RouteLayer = {
                                points: [],
                                id: `Route${routeLayers.length + 1}`,
                                keyPointRef: keypointId
                            };
                            setRouteLayers([...routeLayers, newRouteLayer]);
                            console.log("New route added for keypoint:", keypointId);
                            
                        }
                    } else {
                        console.log("No current keypoint selected");
                    }
                }}> 
                    {lang.Text("Add Route")}
                </Menu.Item>
                <Menu.Item onClick={() => {
                    props.saveRouteLayers(getMarkerLayers());
                }}>
                    {lang.Text("Save routes")}
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item disabled> 
                    {lang.Text("Add Wall")}
                </Menu.Item>
           </Menu.Dropdown>
           </Menu>

           <Switch label={lang.Text("Add points")} value={addPointMode ? "true" : "false"} onChange={(value) => {
                setAddPointMode(value.currentTarget.checked);
                //setAddPointMode(value);
            }}/>
           </Stack>
            )
        }
        return null;    
    }

    useEffect(() => {
        console.log("routeLayers: ", routeLayers);
        updateMarkerLayers(routeLayers);
    }, [routeLayers]);

    const [walls, setWalls] = useState<WallSectionDef[]>([
        {start: {x: 0, y: 0}, end: {x: 0, y: 5}},
        {start: {x: 0, y: 5}, end: {x: 2, y: 5}},
        {start: {x: 2, y: 10}, end: {x: 5, y: 10}}
    ]);


    // const saveRouteLayers = (routeLayer: RouteLayer[]) => {
    //     console.log("saveRouteLayers: ", routeLayer);
       
    //     saveRouteLayers(routeLayer);
    // }

    const {renderMarkerLayer, setActiveMarkerLayer, updateMarkerLayers, getMarkerLayers} = useMarkerLayer({
        routeLayers: routeLayers,
        setIsDragging: setIsDragging,
        saveRouteLayers: props.saveRouteLayers
    });


    const renderMap = () => {
    return (<Canvas style={{ background: "white" }} 
                dpr={[1, 2]}>

        <Suspense fallback={renderLoading()}>
        
        <CanvasReady onReady={props.mapLoaded ? () => {
            if (props.mapLoaded) props.mapLoaded();
                //console.log("map loaded ->", props.layer);
            } : () => { 


            }} />

        <ambientLight intensity={1.0} color={"white"}/>

        <directionalLight position={[5, 10, 5]} intensity={1.8} />
    
        {renderMapLayer(props.layer)}
   
        {/* <planeHelper args={[floorPlane, 4 , 0x65a30d]} /> */}

        {props.editMode ? <gridHelper args={[100, 100, "#DFDFDF", "#DFDFDF"]} /> : null}

        {currentKeyPoints?.map((keyPoint) => (
            <KeyPointMarker key={keyPoint.id} keyPoint={keyPoint} 
                setIsDragging={setIsDragging} 
                floorPlane={floorPlane} 
                saveKeyPoint={saveKeyPoint} 
                editMode={props.editMode}
          />
        ))}


        {/* <WallSection start={[0, 0]} end={[0, 5]} />
        <WallSection start={[0, 5]} end={[2.1, 10.1]} />
        <WallSection start={[2, 10]} end={[5, 10]} /> */}

        {/* <MergedWalls walls={walls} /> */}

        {/* <MarkerLayer editmode={props.editMode ?? false} setIsDragging={setIsDragging} 
            addPointMode={props.addPointMode ?? false}
            routeLayers={routeLayers}
            saveRouteLayers={saveRouteLayers}
        /> */}

        {renderMarkerLayer(props.editMode ?? false, addPointMode)}

        <OrthographicCamera makeDefault 
            zoom={currentZoom} 
            position={cameraPosition}
        />
      
        {/* <Fire scale={2} color={0xeeeeee} position={[0, -5, 0]} /> */}

    </Suspense>

    <CameraControls
        ref={cameraControlsRef}
        makeDefault
        enabled={!isDragging}
        minPolarAngle={- Math.PI / 90}
        maxPolarAngle={ Math.PI /4}
        minZoom={10} 
        maxZoom={60}
        smoothTime={0.8}

        touches={{
            one: 64,
            two: 512,
            three: 32,
          }}
          mouseButtons={{
            left: 2,
            middle: 16,
            right: 1,
            wheel: 16
          }}
          dollySpeed={props.isMobile ? 3 : 1}
    />
    </Canvas>
    )}


    return {
        renderMap,
        showdirections,
        setMapKeyPoints,
        renderTools,
        setMapCurrentKeypoint: setCurrentKeyPoint
    }

}